<template>
    <div class="page-mode mb-12 page-admin-shipments">
        <template v-if="false" slot="tools">
            <div v-if="!loading" class="flex flex__align-center -translate-y-10">
                <modal-field
                    v-if="statisticsData.confirmed"
                    label="AWAITING PICK UP"
                >
                    <div class="flex flex__align-end">
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.625 2.5H10.5C10.5 1.12109 9.37891 0 8 0C6.62109 0 5.5 1.12109 5.5 2.5H2.375C1.33984 2.5 0.5 3.33984 0.5 4.375V18.125C0.5 19.1602 1.33984 20 2.375 20H13.625C14.6602 20 15.5 19.1602 15.5 18.125V4.375C15.5 3.33984 14.6602 2.5 13.625 2.5ZM8 1.5625C8.51953 1.5625 8.9375 1.98047 8.9375 2.5C8.9375 3.01953 8.51953 3.4375 8 3.4375C7.48047 3.4375 7.0625 3.01953 7.0625 2.5C7.0625 1.98047 7.48047 1.5625 8 1.5625ZM12.7344 10.6172L7.14844 16.1562C6.96484 16.3398 6.66797 16.3359 6.48438 16.1523L3.25781 12.8984C3.07422 12.7148 3.07812 12.418 3.26172 12.2344L4.37109 11.1328C4.55469 10.9492 4.85156 10.9531 5.03516 11.1367L6.83203 12.9492L10.9727 8.83984C11.1562 8.65625 11.4531 8.66016 11.6367 8.84375L12.7383 9.95312C12.9219 10.1406 12.918 10.4336 12.7344 10.6172Z" fill="black" fill-opacity="0.5"/>
                        </svg>
                        <div class="text-xl ml-3 lh-16">
                            {{ statisticsData.confirmed.shipments }} shpmts
                            <span v-if="statisticsData.confirmed.xceptions" @click="updateDashboardItem({ label: 'Confirmed', value: 'CONFIRMED' })" class="color-pink cursor-pointer">
                                {{ statisticsData.confirmed.xceptions }} xcs
                            </span>
                            <span v-else class="color-green">
                                {{ statisticsData.confirmed.xceptions }} xcs
                            </span>
                        </div>
                    </div>
                </modal-field>

                <modal-field
                    v-if="statisticsData.pickup"
                    label="AWAITING CONFIRMATION"
                    class="ml-12 lh-16"
                >
                    <div class="flex flex__align-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5481 5.025L15.8633 7C15.8779 7.05022 15.8828 7.10184 15.8877 7.15393C15.8901 7.1796 15.8925 7.20546 15.8961 7.23125H9.36914L9.43751 4H13.3039C13.8699 4 14.3703 4.4125 14.5481 5.025ZM4.69611 4H8.56251L8.49414 7.23125H1.96719C1.9708 7.20544 1.97322 7.17963 1.97563 7.15393C1.98051 7.10184 1.98535 7.05022 2 7L3.45197 5.025C3.6297 4.4125 4.13009 4 4.69611 4Z" fill="black" fill-opacity="0.5"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00001 18.5L1.93164 8.23125H15.9316L15.9625 12.8709H9.5C9.15621 12.8709 8.87492 13.1254 8.87492 13.4363V14.5672C8.87492 14.8782 9.15621 15.1326 9.5 15.1326H15.9776L16 18.5C16 19.3281 15.4121 20 14.6875 20H3.31251C2.5879 20 2.00001 19.3281 2.00001 18.5Z" fill="black" fill-opacity="0.5"/>
                            <path d="M18.0013 10.5669V12.8709H15.9625L15.9776 15.1326H17.9974V17.4331C17.9974 17.9385 18.6733 18.1894 19.0678 17.8325L22.8066 14.4258C23.0645 14.1926 23.0645 13.8145 22.8066 13.5777L19.0718 10.1675C18.6772 9.8106 18.0013 10.0615 18.0013 10.5669Z" fill="black" fill-opacity="0.5"/>
                        </svg>

                        <div class="text-xl ml-3">
                            {{ statisticsData.pickup.shipments }} shpmts
                            <span v-if="statisticsData.pickup.xceptions" @click="updateDashboardItem({ label: 'Picked up', value: 'PICKED_UP' })" class="color-pink cursor-pointer">
                                {{ statisticsData.pickup.xceptions }} xcs
                            </span>
                            <span v-else class="color-green">
                                {{ statisticsData.pickup.xceptions }} xcs
                            </span>
                        </div>
                    </div>
                </modal-field>

                <modal-field
                    v-if="statisticsData.transit"
                    label="IN TRANSIT"
                    class="ml-12"
                >
                    <div class="flex flex__align-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5 15H21V11.6219C21 11.225 20.8406 10.8438 20.5594 10.5625L17.4375 7.44062C17.1562 7.15937 16.775 7 16.3781 7H15V5.5C15 4.67188 14.3281 4 13.5 4H3.5C2.67188 4 2 4.67188 2 5.5V15.5C2 16.3281 2.67188 17 3.5 17H4C4 18.6562 5.34375 20 7 20C8.65625 20 10 18.6562 10 17H14C14 18.6562 15.3438 20 17 20C18.6562 20 20 18.6562 20 17H21.5C21.775 17 22 16.775 22 16.5V15.5C22 15.225 21.775 15 21.5 15ZM7 18.5C6.17188 18.5 5.5 17.8281 5.5 17C5.5 16.1719 6.17188 15.5 7 15.5C7.82812 15.5 8.5 16.1719 8.5 17C8.5 17.8281 7.82812 18.5 7 18.5ZM17 18.5C16.1719 18.5 15.5 17.8281 15.5 17C15.5 16.1719 16.1719 15.5 17 15.5C17.8281 15.5 18.5 16.1719 18.5 17C18.5 17.8281 17.8281 18.5 17 18.5ZM19.5 12H15V8.5H16.3781L19.5 11.6219V12Z" fill="black" fill-opacity="0.5"/>
                        </svg>

                        <div class="text-xl ml-3 lh-16">
                            {{ statisticsData.transit.shipments }} shpmts
                            <span v-if="statisticsData.transit.xceptions" @click="updateDashboardItem({ label: 'In transit', value: 'IN_TRANSIT' })" class="color-pink cursor-pointer">
                                {{ statisticsData.transit.xceptions }} xcs
                            </span>
                            <span v-else class="color-green">
                                {{ statisticsData.transit.xceptions }} xcs
                            </span>
                        </div>
                    </div>
                </modal-field>
            </div>
            <div v-else class="flex flex__align-center -translate-y-10">
                <spinning-clock class="w-6 h-6" />
            </div>
        </template>

        <edit-shipment-modal
            :shipment="activeShipment"
            :show="showEditShipmentModal"
            @hide="showEditShipmentModal = false"
            v-if="adminRoute"
        />
        <snooze-modal v-if="snoozeModalHide" @hide="snoozeModalHide = false" :uuids="snoozeModalData" @success="snoozeSuccess" />
        <generate-email-modal v-if="generateEmailHide" @generateEmailHide="generateEmailHide = false" :value="generateEmailData" @refreshTable="refreshTable" />
        <set-time-modal v-if="setTimeHide" @setTimeHide="setTimeHide = false" :value="setTimeValue" @refreshTable="refreshTable" />
        <comment-modal v-if="commentHide" @commentHide="commentHide = false" @refreshTable="refreshTable" :value="commentId" />
        <send-information-modal v-if="sendInformationHide" @sendInformationHide="sendInformationHide = false" @refreshTable="refreshTable" :value="sendInformationData" />
        <add-event-modal :shipment="activeShipment" @created="refreshTable" v-if="adminRoute" />
        <edit-shipment-modal :shipment="activeShipment" @updated="refreshTable" v-if="adminRoute" />
        <emissions-import-consignment-dialog
            @hide="hideImportConsignment"
            :showing="showImportConsignmentDialog"
            :refreshTrigger="refreshTable"
        ></emissions-import-consignment-dialog>
        <card body-class="overflow-visible pb-0 card-shadow" class="border-radius--l">
            <div class="flex flex__column w__100-p shipments-filters-drag" :class="{'shipments-filters-drag--active': asFilterControl}">
                <div class="flex flex__justify-between w__100-p shipments-hot-panel">
                    <div class="flex flex__grow">
                        <draggable
                            v-if="initDragg"
                            :list="filterHeader"
                            draggable=".item"
                            handle=".text-draggable"
                            :group="hotGroup"
                            class="flex flex__grow shipments-hot-block shipments-drag-zone"
                            :options="{disabled: !asFilterControl}"
                            :class="{'shipments-drag-zone--active': asFilterControl && asFilterDrag }"
                            @start="onStart"
                            @end="asFilterDrag = false"
                        >
                            <filter-item
                                v-model="generateFilter(item)[item.model]"

                                v-for="item in filterHeader"
                                :key="item.template"

                                :template="item.template"
                                :type-form="item.typeForm"
                                :adminRoute="adminRoute"
                                :user="user"
                                :options="options"
                                :organizations="organizations"
                                :loaded-range="loadedRange"
                                :datesTrigger="datesTrigger"

                                :filter="item.filter"
                                :filterModel="item.filterModel"
                                :optionsName="item.optionsName"
                                :owner_list="owner_list"
                                :createdComponents="createdComponents"

                                :branding="item.branding"
                                :group-label="user.level === 'super' ? 'name' : ''"
                                :group-select="item.groupSelect"
                                :group-values="user.level === 'super' ? 'sites' : ''"
                                :class="[item.className, { 'mt-4': item.typeForm === 'checkbox' }]"
                                :name="item.name"
                                :label="item.label"
                                :placeholder="item.placeholder"
                                :track-by="item.trackBy"
                                :multiple="item.multiple"
                                :remove-all-item="item.removeAllItem"
                                :remove-default-value="item.removeDefaultValue"
                                :initAll="item.initAll"

                                class="item"
                            />
                        </draggable>
                    </div>

                    <div v-if="!asFilterControl" class="flex flex__align-center shipments-hot-panel__bar">
                        <icon name="icons/settings" title="Show all filters" class="ml-4" @click="showAdvanced = !showAdvanced" />

                        <icon name="icons/filter-control" title="Filter control" class="ml-4" @click="onFilterControl" />

                        <confirm @confirmed="resetData" confirm-text="Reset form">
                            <icon name="icons/reset" title="Reset form" class="hover:text-red-500 ml-4" />
                        </confirm>
                    </div>

                    <div v-else class="flex flex__align-center shipments-hot-panel__bar">
                        <p class="no-wrap">Save this layout?</p>

                        <icon name="icons/check" title="Save filters" class="icon-btn ml-4" key="shipments-admin-confirm" @click="saveFilterControl" />

                        <icon name="icons/close" title="Reset filters" class="icon-btn ml-4" key="shipments-admin-close" @click="cancelFilterControl" />
                    </div>
                </div>

                <collapse-transition>
                    <div class="flex flex-col w-full shipments-all-filters" v-show="showAdvanced">
                        <h5 class="mt-3 mb-2">Advanced filters</h5>

                        <div class="flex flex__justify-between mt-4">
                            <div class="flex__grow pr-4">
                                <draggable
                                    v-if="initDragg"
                                    :list="filterSelects"
                                    draggable=".item"
                                    handle=".text-draggable"
                                    group="a"
                                    class="flex flex__wrap w-full shipments-selects-block shipments-drag-zone"
                                    :options="{disabled: !asFilterControl}"
                                    :class="{'shipments-drag-zone--active': asFilterControl && asFilterDrag && hotGroup === 'a'}"
                                    @start="hotGroup = 'a'; asFilterDrag = true"
                                    @end="asFilterDrag = false"
                                >
                                    <filter-item
                                        v-model="generateFilter(item)[item.model]"

                                        v-for="item in filterSelects"
                                        :key="item.template"

                                        :template="item.template"
                                        :type-form="item.typeForm"
                                        :adminRoute="adminRoute"
                                        :user="user"
                                        :options="options"
                                        :organizations="organizations"
                                        :loaded-range="loadedRange"
                                        :datesTrigger="datesTrigger"

                                        :filter="item.filter"
                                        :filterModel="item.filterModel"
                                        :optionsName="item.optionsName"
                                        :owner_list="owner_list"
                                        :createdComponents="createdComponents"

                                        :branding="item.branding"
                                        :group-label="user.level === 'super' ? 'name' : ''"
                                        :group-select="item.groupSelect"
                                        :group-values="user.level === 'super' ? 'sites' : ''"
                                        :class="item.className"
                                        :name="item.name"
                                        :label="item.label"
                                        :placeholder="item.placeholder"
                                        :track-by="item.trackBy"
                                        :multiple="item.multiple"
                                        :remove-all-item="item.removeAllItem"
                                        :remove-default-value="item.removeDefaultValue"
                                        :initAll="item.initAll"
                                        :max-selectable="item.maxSelectable"
                                        :disabled-select-all="item.disabledSelectAll"

                                        class="item mr-4 mb-4"
                                    />
                                </draggable>
                            </div>

                            <div
                                class="relative shipments-switch-block-wrapper shipments-drag-zone"
                                style="width: 300px; min-width: 300px"
                                :class="{'shipments-drag-zone--active': asFilterControl && asFilterDrag && hotGroup === 'b'}"
                            >
                                <draggable
                                    v-if="initDragg"
                                    :list="filterSwitch"
                                    draggable=".item"
                                    handle=".text-draggable"
                                    group="b"
                                    class="shipments-switch-block"
                                    :options="{disabled: !asFilterControl}"
                                    @start="hotGroup = 'b'; asFilterDrag = true"
                                    @end="asFilterDrag = false"
                                >
                                    <filter-item
                                        v-model="generateFilter(item)[item.model]"

                                        v-for="item in filterSwitch"
                                        :key="item.template"

                                        :template="item.template"
                                        :type-form="item.typeForm"
                                        :adminRoute="adminRoute"
                                        :user="user"
                                        :options="options"
                                        :organizations="organizations"
                                        :loaded-range="loadedRange"
                                        :datesTrigger="datesTrigger"

                                        :filter="item.filter"
                                        :filterModel="item.filterModel"
                                        :optionsName="item.optionsName"
                                        :owner_list="owner_list"
                                        :createdComponents="createdComponents"

                                        :branding="item.branding"
                                        :group-label="user.level === 'super' ? 'name' : ''"
                                        :group-select="item.groupSelect"
                                        :group-values="user.level === 'super' ? 'sites' : ''"
                                        :class="item.className"
                                        :name="item.name"
                                        :label="item.label"
                                        :placeholder="item.placeholder"
                                        :track-by="item.trackBy"
                                        :multiple="item.multiple"
                                        :remove-all-item="item.removeAllItem"
                                        :remove-default-value="item.removeDefaultValue"
                                        :initAll="item.initAll"

                                        class="item"
                                    />
                                </draggable>
                            </div>
                        </div>
                    </div>
                </collapse-transition>
            </div>
            <!-- <pre>{{ options }}</pre> -->
        </card>
        
        <card
            v-if="!isLoadingTable"
            body-class="p-0 -mx-6 overflow-visible page-mode-table-body"
            class="shipments-table page-mode-table bg-transparent mb-6"
        >
            <app-table
                :branding="!adminRoute"
                :class="{'admin-table': adminRoute }"
                :columns="columns"
                :filter-params="filterParams"
                :id="tableRef"
                :loading="loadingConsignments"
                :ref="tableRef"
                :rows="consignments"
                :tools="false"
                :panel="true"
                :saved-rows="savedRows()"
                :right-tools="false"
                :selected="checkedRows.length"
                @check-row="rowChecked"
                @row-dbclick="rowClicked"
                paginate
                checkable
                :endpoint="$apiUrl.consignments.base"
                query-params
                :draggable="true"
                row-key="uuid"
                :sort-key="shipmentsFilter && shipmentsFilter.sort_key ? shipmentsFilter.sort_key : 'ordered_at'"
                :sort-order="shipmentsFilter && shipmentsFilter.sort_order ? Number(shipmentsFilter.sort_order) : -1"
                class="t-draggable page-mode-table-content"
                :saved-data="shipmentsFilter"
                custom-checkable
                @table-rows="getTableRows"
                @updated-columns="updatedColumns"
                @updated-page-size="updatedPageSize"
                @updated-page="updatedPage"
                @updated-sort-key="updateSortKey"
                @updated-sort-order="updateSortOrder"
                @send-response="pageLoad = true"
                @unsee="makeUnseen"
            >
                <template slot="panel_right">
                    <div class="relative flex flex__align-center mr-4">
                        <icon name="icons/save" title="Save query" class="ml-4 outline-default" @click="showSaveQueryModal = true" />

                        <icon name="icons/load-query" title="Load query" class="ml-4 outline-default" @click="showLoadQueryModal = true" />

                        <icon name="icons/forward" title="Share the filter link" class="ml-4 outline-default" @click="shareFilterLink" />
                    </div>
                </template>

                <template slot="panel">
                    <div class="relative flex items tools flex__align-center">
                        <icon
                            name="icons/bookmark"
                            :disabled="toggleTableTools || checkedRows.length == 0"
                            class="mr-4"
                            @click="updateBookmarked"
                        />
                    </div>
                </template>

                <template slot="export">
                    <div class="relative flex flex__align-center">
                        <icon
                            name="icons/download"
                            :disabled="toggleTableTools"
                            @click="toExport()"
                            class="outline-default mr-4"
                            title="Export"
                        />

                        <icon
                            name="icons/document-right"
                            class="outline-default  mr-4"
                            title="Import"
                            @click="showImportConsignment()"
                        />

                        <icon
                            name="icons/document-plus"
                            :disabled="checkedRows.length == 0"
                            class="outline-default mr-4"
                            title="Snooze and reminder"
                            @click="snoozeAll"
                        />

                        <icon
                            name="icons/document-minus"
                            :disabled="checkedRows.length == 0"
                            class="outline-default mr-4"
                            title="Close snooze"
                            @click="snoozeAllClose"
                        />

                        <icon
                            name="icons/document-closed"
                            :disabled="checkedRows.length == 0"
                            class="outline-default mr-4"
                            title="Close reminder"
                            @click="reminderAllClose"
                        />
                    </div>
                </template>

                <template slot="cell" slot-scope="{row, col}">
                    <span v-if="dateFieldNames.includes(col.key)">
                        {{ formatDate(row[col.key]) }}
                    </span>
                    <template v-else-if="Object.entries(addressRelatedKeys).some(entry => entry[1].includes(col.key))">
                        <span :title="getDeliveryTypeField(col.key, row)">
                            {{ getDeliveryTypeField(col.key, row) }}
                        </span>
                    </template>
                    <span v-else-if="col.key === 'exception_details'" :title="prepareExceptionDetails(row.exception_details)">
                        {{ prepareExceptionDetails(row.exception_details) }}
                    </span>
                    <span v-else-if="col.key === 'pick_up_starts_at'">
                        {{ formatDate(row.pick_up_starts_at || row.pick_up_ends_at) }}
                    </span>
                    <span v-else-if="col.key === 'estimated_delivery_starts_at'">
                        {{ formatDate(row.estimated_delivery_starts_at || row.estimated_delivery_ends_at) }}
                    </span>
                    <span v-else-if="col.key == 'price_data'">
                        {{ row.price_data.amount || '–' }} {{ row.price_data.currency || '' }}
                    </span>

                    <span v-else-if="col.key == 'dimensions_weight'" :title="roundNumber(row.dimensions_weight, row.dimensions_weight_unit)">
                        {{ roundNumber(row.dimensions_weight, row.dimensions_weight_unit) }}
                    </span>

                    <span v-else-if="col.key == 'dimensions_volume'" :title="roundNumber(row.dimensions_volume, row.dimensions_volume_unit)">
                        {{ roundNumber(row.dimensions_volume, row.dimensions_volume_unit) }}
                    </span>

                    <span v-else-if="col.key == 'noofpackages'">{{ row.noofpackages || 0 }} pkgs</span>
                    <span class="capitalize" v-else-if="col.key == 'status'">
                        <fa
                            :class="getStatusColor(row.status)"
                            :icon="getStatusIcon(row.status)"
                            class="text-xs"
                            transform="shrink-5"
                        />
                        {{ row.status ? row.status.replace("_"," ").toLowerCase() : 'No status' }}
                    </span>
                    <!--<template v-else-if="col.key == 'order'">
                        <fa
                            class="ml-2"
                            :icon="['fal','link']"
                            v-if="(Math.round(Math.random())) > .5"
                            transform="grow-10"
                        />
                    </template>-->
                    <span v-else-if="col.key == 'is_archived'">
                        {{ row.is_archived ? 'Archived' : '' }}
                    </span>
                    <span v-else-if="col.key == 'actions' && adminRoute" class="action">
                        <shipment-actions
                            :table-ref="tableRef"
                            :exception_details="props_exception_details"
                            :value="row"
                            @action-go="runAction($event, row)"
                        />
                    </span>
                    <div
                        :title="row.transport_mode"
                        class="pl-4 transportmode-icon"
                        v-else-if="col.key == 'transport_mode'"
                    >
                        <svg-importer
                            :icon-name="`icons/${getTransportModeIcon(row.transport_mode)}`"
                            :key="`${row.id}-${col.key}_${row.transport_mode}`"
                        />
                    </div>
                    <div
                        :title="row.carrier_data.carrier_name"
                        class="h-6 pl-4"
                        v-else-if="col.key == 'carrier_from_source'"
                    >
                        <!--<img v-if="getCarrierLogo(row.carrier_from_source)" class="w-16 max-h-6" :src="getCarrierLogo(row.carrier_from_source)" />-->
                        <strong>{{ row.carrier_data.carrier_name }}</strong>
                    </div>

                    <template v-else-if="col.title === 'Carrier service'">
                        <div :title="parseCarrierService(row.carrier_data.carrier_service_name)">
                            <strong class="truncate">{{ parseCarrierService(row.carrier_data.carrier_service_name) }} <fa v-if="filteredCarriers.length < 1" class="text-xl fa-spin f-size-12-i ml-1" :icon="['fad','spinner-third']" /></strong>
                        </div>
                    </template>

                    <template v-else-if="col.key === 'booking_number'">
                        <div v-if="row.booking_number" :title="row.user_name ? `${row.booking_number} - ${row.user_name}` : row.booking_number" class="truncate">
                            {{ row.booking_number }}
                            <span v-if="row.user_name"> - {{ row.user_name }}</span>
                        </div>
                    </template>

                    <template
                        v-else-if="col.key == 'purchase_orders'"
                    >

                        <expansion-item v-if="row.purchase_orders.length"
                                        :items="row.purchase_orders"
                                        textCount="orders"
                                        itemParamValue="reference"
                                        routeName="purchase.orders.detail-by-id"
                                        routeParamName="id" />

                    </template>

                    <template
                        v-else-if="col.key == 'price_cat1'"
                    >
                        <div>
                            {{
                                getIsDisplayPrice(row.price_data) ? `${roundNumber(row.price_data.amount)} ${row.price_data.currency}` : '-'
                            }}
                        </div>
                    </template>

                    <template v-else-if="col.key === 'dimensions_loadingmetres'">
                        <div :title="roundNumber(row.dimensions_loadingmetres)">
                            {{ roundNumber(row.dimensions_loadingmetres)  }}
                        </div>
                    </template>

                    <template v-else-if="col.key === 'manual_updated_at'">
                        <span :title="getTimePlaceholder(row.manual_updated_at)">
                            {{ timeAgo(row.manual_updated_at) }}
                        </span>
                    </template>

                    <template v-else-if="col.key === 'count_documents'">
                        {{ row.count_documents || '-' }}
                    </template>

                    <span v-else-if="col.key === 'goods_types'" :title="getGoodsTypes(row[col.key])">
                        {{ getGoodsTypes(row[col.key]) }}
                    </span>

                    <span
                        v-else-if="getIsFromFullAddress(col.key)"
                        v-text="getFullAddressField({ row, key: col.key })"
                    />

                    <span v-else-if="col.key === 'direction'" class="capitalize">
                        {{ row[col.key] }}
                    </span>

                    <span v-else-if="col.key === 'source'" class="capitalize">
                        {{ row[col.key] }}
                    </span>

                    <span v-else>{{ row[col.key] }}</span>
                </template>
            </app-table>
        </card>

        <modal :show="contactModal" @hide="contactModal = false">
            <h2 slot="header">Contact vChain</h2>
            <div class="p-4" slot="body">
                <div v-if="!contact.sending && !contact.sent">
                    <modal-field class="mb-4" label="Subject">
                        <input type="text" v-model="contact.subject" />
                    </modal-field>

                    <modal-field class="mb-4" label="Message">
                        <textarea rows="8" type="text" v-model="contact.message" />
                    </modal-field>

                    <div>
                        <small>
                            <em>Your contact info will be appended automatically.</em>
                        </small>
                    </div>

                    <div class="mt-2 overflow-auto bg-grey-lighter" style="max-height: 200px;">
                        <ul>
                            <li
                                :key="consignment.consignment_no"
                                class="text-sm"
                                v-for="consignment in checkedRows"
                            >
                                <strong>{{ consignment.consignment_no }}</strong>
                                {{ consignment.consignor_name }} - {{ consignment.consignee_name }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="relative text-center" v-if="contact.sending && !contact.sent">
                    <div class="w-16 m-auto">
                        <circular-loader />
                    </div>Sending mail
                </div>
                <div class="relative text-center" v-if="contact.sent">
                    <span v-if="!contact.withError">
                        Your message was sent to
                        <span class="text-green">{{ contact.recipient }}</span>!
                    </span>
                    <span
                        class="text-red"
                        v-else
                    >Something went wrong, your mail was not sent. Please try again.</span>
                </div>
            </div>

            <div class="flex w-full" slot="footer">
                <button
                    @click="closeContactModal"
                    class="ml-auto mr-4 text-sm text-red-500"
                    v-if="!contact.sent"
                >Cancel</button>

                <button
                    @click="closeContactModal"
                    class="ml-auto btn-green btn"
                    v-if="contact.sent"
                >OK</button>

                <button @click="sendToVchain" class="btn" v-if="!contact.sent">Send</button>
            </div>
        </modal>

        <modal class="load-query-modal" :show="showLoadQueryModal" size="md" @hide="showLoadQueryModal = false">
            <h2 slot="header">Load query</h2>
            <div class="p-4 pb-24" slot="body">
                <div
                    :class="{'border-t': index > 0}"
                    :key="index"
                    class="flex items-center w-full pt-2 mb-2"
                    v-for="(query, index) in loadedQueries"
                >
                    <span class="capitalize">{{ query.title }}</span>
                    <span class="ml-3 text-sm text-gray-600">Created {{ query.created_at }}</span>

                    <confirm @confirmed="loadQuery(query)" class="ml-auto" confirm-text="Yes">
                        <button class="ml-auto btn btn-sm">Load</button>
                    </confirm>
                    <button class="ml-3 btn btn-sm btn-red no-branding" @click="removeQuery(query)">Delete</button>
                </div>
            </div>
        </modal>

        <modal :loading="savingQuery" :show="showSaveQueryModal" size="md" @hide="showSaveQueryModal = false">
            <h2 slot="header">Save query</h2>
            <div class="p-4" slot="body">
                <modal-field label="Query name:">
                    <input type="text" v-model="saveQuery.title" />
                </modal-field>
            </div>

            <div class="flex w-full" slot="footer">
                <button
                    @click="showSaveQueryModal = false"
                    class="ml-auto mr-4 text-sm text-red-500"
                >Cancel</button>
                <button @click="postSaveQuery" class="btn">Save</button>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Draggable from 'vuedraggable'
import GenerateEmailModal from '~/pages/transport/modals/GenerateEmailModal';
import { serializeParams } from '~/components/vtable/utils'
import ShipmentActions from '~/pages/admin/invoice/ActionsShipments'
import SetTimeModal from '~/pages/transport/modals/SetTimeModal'
import AddEventModal from '~/pages/transport/modals/AddEventModal'
import EditShipmentModal from '~/pages/transport/modals/EditShipmentModal'
import SnoozeModal from '~/pages/transport/modals/SnoozeModal'
import CommentModal from '~/pages/transport/modals/CommentModal'
import SendInformationModal from '~/pages/transport/modals/SendInformationModal'
import ExpansionItem from '~/components/Expansion/ExpansionItem'
import FilterItem from '~/components/ShipmentsAdmin/FilterItem';
import AppTable from '~/components/vtable/AppTable';
import EmissionsImportConsignmentDialog from "~/pages/transport/emissions/EmissionsUploadImportConsignmentsDialog";
import { CollapseTransition } from "@ivanv/vue-collapse-transition"
import { prepareFiltersForSave } from '~/utils/filter.js';
import { roundNumber } from '@/utils/roundNumber.js';
import { requestErrors } from '~/utils/errors';
import ShipmentsAddressMixin from './shipments-address.mixin';
import filterOptions from '~/data/consignments-filter-options';
import { replacePriceExportColumn } from '~/utils/replacePriceExportColumn';

function defaultFilterOptions() {
    return {
        date_between: [],
        owner_site_from_source: [],
        transport_mode: [],
        consignor_name: [],
        consignor_countrycode: [],
        consignor_city: [],
        consignee_name: [],
        consignee_countrycode: [],
        consignee_city: [],
        pickup_address: [],
        delivery_address: [],
        carrier_from_source: [],
        purchase_orders: [],
        carrier_service_id: [],
        duplicates_by: [],
        shipment_status: [],
        exception_at: [],
        exception_details: [],
        carrier_service_from_source: [],
        users: [],
        bookmark: [],
        carrier_ids: [],
        carrier_service_ids: [],
        owner_data_list: [],
        owner_client_list: [],
        is_archived: [],
        daily_pickup: [],
        direction: filterOptions.direction,
    }
}

const filterCategory = 'admin-shipments';

export default {
    name: 'Shipments',
    mixins: [ShipmentsAddressMixin],
    components: {
        EditShipmentModal,
        SnoozeModal,
        ShipmentActions,
        SetTimeModal,
        AddEventModal,
        ExpansionItem,
        GenerateEmailModal,
        CommentModal,
        SendInformationModal,
        CollapseTransition,
        Draggable,
        FilterItem,
        AppTable,
        EmissionsImportConsignmentDialog,
    },

    metaInfo() {
        return { title: 'Shipments' }
    },
    data: () => ({
        dateFieldNames: [
            'ordered_at',
            'estimated_time_departure_at',
            'actual_time_departure_at',
            'actual_time_arrival_at',
            'actual_time_delivery_at',
            'actual_time_pick_up_at',
            'estimated_arrival_at',
            'etadate'
        ],
        filtersVersion: 1,
        initDragg: false,
        createdComponents: false,
        hotGroup: 'a',
        asFilterControl: false,
        asFilterDrag: false,
        shipmentsFilter: null,
        isLoadingTable: true,
        pageLoad: false,
        isResetData: false,
        usersList: [],
        formMulti: null,
        filterHeader: [
            {
                template: 'by_reference',
                typeForm: 'input',
                name: 'Consignment # / Reference #',
                model: 'by_reference',
                filter: true,
            },
            {
                template: 'owner',
                typeForm: 'multi-select',
                name: 'Filter by client',
                optionsName: 'owner_client_list',
                model: 'owner_from_source',
                key: 'id',
                filter: true,
            },
            {
                template: 'transport_mode',
                typeForm: 'multi-select',
                name: 'Transport mode(s)',
                optionsName: 'transport_mode',
                model: 'transport_mode',
            },
            {
                template: 'date',
                typeForm: 'date',
                name: 'Date',
                fullList: true,
                model: 'range',
                filter: true,
            },
            {
                template: 'is-in-progress',
                typeForm: 'checkbox',
                name: 'Active shipments',
                model: 'is_in_progress',
                filter: true,
            },
            {
                template: 'is_time_definite',
                typeForm: 'checkbox',
                name: 'Time-definite',
                model: 'is_time_definite',
                filter: true,
            },
            {
                template: 'exceptions',
                typeForm: 'checkbox',
                name: 'Exceptions',
                model: 'has_exception',
                filter: true,
            },
        ],
        filterSelects: [
            {
                template: 'filter-site',
                typeForm: 'multi-select',
                name: 'Filter site',
                optionsName: 'owner_data_list',
                model: 'selectedSite',
            },
            {
                template: 'carrier_service_ids',
                typeForm: 'multi-select',
                name: 'Carrier service',
                optionsName: 'carrier_service_ids',
                model: 'carrier_service_ids',
                label: 'name',
                trackBy: 'id',
            },
            //
            {
                template: 'carriers',
                typeForm: 'multi-select',
                name: 'Carriers',
                optionsName: 'carrier_ids',
                model: 'carrier_ids',
                label: 'name',
                trackBy: 'id',
            },
            {
                template: 'exception-details',
                typeForm: 'multi-select',
                name: 'Exception details',
                optionsName: 'exception_details',
                label: 'label',
                trackBy: 'value',
                model: 'exception_details',
            },
            {
                template: 'investigated_by',
                typeForm: 'select',
                name: 'Investigated by',
                optionsName: 'users',
                model: 'investigated_by',
            },
            {
                template: 'from-company',
                typeForm: 'multi-select',
                name: 'From company',
                optionsName: 'consignor_name',
                model: 'consignor_name',
                filter: true,
                maxSelectable: 10,
                disabledSelectAll: true,
            },
            {
                template: 'from_postcode',
                typeForm: 'input',
                name: 'Consignor ZIP',
                model: 'from_postcode',
                filter: true,
            },
            {
                template: 'pickup_postcode',
                typeForm: 'input',
                name: 'Pickup ZIP',
                model: 'pickup_postcode',
                filter: true,
            },
            {
                template: 'consignor_pickup_countrycode',
                typeForm: 'multi-select',
                name: 'From country',
                optionsName: 'consignor_countrycode',
                model: 'consignor_pickup_countrycode',
                filter: true,
            },
            {
                template: 'consignor_city',
                typeForm: 'multi-select',
                name: 'From city',
                optionsName: 'consignor_city',
                model: 'consignor_city',
                filter: true,
            },
            {
                template: 'to-company',
                typeForm: 'multi-select',
                name: 'To company',
                optionsName: 'consignee_name',
                model: 'consignee_name',
                filter: true,
                maxSelectable: 10,
                disabledSelectAll: true,
            },
            {
                template: 'to_postcode',
                typeForm: 'input',
                name: 'Consignee ZIP',
                model: 'to_postcode',
                filter: true,
            },
            {
                template: 'delivery_postcode',
                typeForm: 'input',
                name: 'Delivery ZIP',
                model: 'delivery_postcode',
                filter: true,
            },
            {
                template: 'consignee_delivery_countrycode',
                typeForm: 'multi-select',
                name: 'To country',
                optionsName: 'consignee_countrycode',
                model: 'consignee_delivery_countrycode',
                filter: true,
            },
            {
                template: 'consignee_city',
                typeForm: 'multi-select',
                name: 'To city',
                optionsName: 'consignee_city',
                model: 'consignee_city',
                filter: true,
            },
            // {
            //     template: 'investigated_by',
            //     typeForm: 'select',
            //     name: 'Investigated by',
            //     optionsName: 'users',
            //     model: 'investigated_by',
            // },
            {
                template: 'shipment-status',
                typeForm: 'multi-select-colors',
                name: 'Shipment status',
                optionsName: 'shipment_status',
                label: 'label',
                trackBy: 'value',
                model: 'statuses',
            },
            {
                template: 'current-etd-date',
                typeForm: 'date',
                name: 'Estimated Departure Date',
                fullList: true,
                model: 'etd_date_between',
                filter: true,
                removeDefaultValue: true,
            },
            {
                template: 'current-eta-date',
                typeForm: 'date',
                name: 'Estimated Delivery Date',
                fullList: true,
                model: 'eta_date_between',
                filter: true,
                removeDefaultValue: true,
            },
            {
                template: 'arrival_date_between',
                typeForm: 'date',
                name: 'Estimated arrival date',
                fullList: true,
                model: 'arrival_date_between',
                filter: true,
                removeDefaultValue: true,
            },
            {
                template: 'is_archived',
                typeForm: 'select',
                name: 'Archived',
                optionsName: 'is_archived',
                model: 'is_archived',
                filter: true,
            },
            {
                template: 'source',
                typeForm: 'multi-select',
                name: 'Source',
                optionsName: 'source',
                model: 'source',
                label: 'name',
                trackBy: 'value',
                filter: true,
            },
            {
                template: 'daily_pickup',
                typeForm: 'select',
                name: 'Daily pickup',
                optionsName: 'daily_pickup',
                model: 'daily_pickup',
                filter: true,
            },
            // {
            //     template: 'bookmark',
            //     typeForm: 'multi-select',
            //     name: 'Bookmarked',
            //     optionsName: 'bookmark',
            //     model: 'bookmark',
            //     label: 'label',
            //     trackBy: 'label'
            // },
            // {
            //     template: 'order_no',
            //     typeForm: 'input',
            //     name: 'Reference #',
            //     model: 'order_no',
            //     filter: true,
            // },
            {
                template: 'direction',
                typeForm: 'multi-select',
                name: 'Direction',
                optionsName: 'direction',
                model: 'direction',
                label: 'name',
                trackBy: 'id',
                filter: true,
            },
        ],
        filterSwitch: [
            {
                template: 'has_loading_meters',
                typeForm: 'checkbox',
                name: 'Non-stackable',
                model: 'has_loading_meters',
                filter: true,
            },
            {
                template: 'is_heavy',
                typeForm: 'checkbox',
                name: 'Heavy goods',
                model: 'is_heavy',
                filter: true,
            },
            {
                template: 'has_bookmarks',
                typeForm: 'checkbox',
                name: 'Bookmarked',
                model: 'has_bookmarks',
                filter: true,
            },
            {
                template: 'under_investigation',
                typeForm: 'checkbox',
                name: 'U. investigation',
                model: 'under_investigation',
                filter: true,
            },
            {
                template: 'duplicates_by',
                typeForm: 'checkbox',
                name: 'D. bookings',
                model: 'duplicates_by',
                filter: true,
            },
            {
                template: 'under_snooze',
                typeForm: 'checkbox',
                name: 'U. Snooze',
                model: 'under_snooze',
                filter: true,
            },
            {
                template: 'under_reminder',
                typeForm: 'checkbox',
                name: 'U. Reminder',
                model: 'under_reminder',
                filter: true,
            },
            {
                template: 'under_reminder_expired',
                typeForm: 'checkbox',
                name: 'U. ex. reminder',
                model: 'under_reminder_expired',
                filter: true,
            },
        ],
        copyFiltersDrag: {
            filterHeader: [],
            filterSelects: [],
            filterSwitch: [],
        },
        datesTrigger: 0,
        applications: [],
        allRowsChecked: false,
        toggleTableTools: false,
        showAdvanced: false,
        showSaveQueryModal: false,
        showLoadQueryModal: false,
        showSaveTemplatesModal: false,
        showLoadTemplatesModal: false,
        savingQuery: false,
        loadedQueries: null,
        loadedTemplates: null,
        loadedRange: false,
        contactModal: false,
        ownerOptions: [],
        direction: [],
        siteOptions: [],
        organizations: [],
        selectedSite: [],
        statuses: [],
        carrier_service_id: [],
        exception_at: [],
        carrier_ids: [],
        carrier_service_ids: [],
        transport_mode: [],
        exception_details: [],
        props_exception_details: [],
        has_exception: 0,
        generateEmailHide: false,
        generateEmailData: {},
        snoozeModalHide: false,
        snoozeModalData: [],
        commentHide: false,
        setTimeHide: false,
        setTimeValue: { id: '', value: '' },
        commentId: null,
        sendInformationHide: false,
        sendInformationData: {},
        // statisticsData: {},
        owner_list: [],
        dictionary: {},
        watchTrigger: 0,
        handlerOwnerSite: true,
        order_no: '',
        to_postcode: '',
        delivery_postcode: '',
        pickup_postcode: '',
        consignment_no: '',
        from_postcode: '',
        investigated_by: null,
        activeShipment: {
            data: {}
        },
        showEditShipmentModal: false,
        contact: {
            subject: null,
            message: null,
            recipient: 'support@vchain.se',
            type: 'contact',
            sending: false,
            withError: false,
            sent: '',
        },
        bookmark: null,
        additionalQueryParams: [],
        filters: {
            range: 'last_week',
            eta_date_between: {
                startDate: '',
                endDate: ''
            },
            etd_date_between: {
                startDate: '',
                endDate: ''
            },
            arrival_date_between: {
                startDate: '',
                endDate: ''
            },
            investigated_by: null,
            by_reference: null,
            date_between: null,
            status: null,
            carrier_service_name: null,
            carrier_service_from_source: null,
            transport_mode: null,
            duplicates_by: 0,
            consignor_name: null,
            consignor_countrycode: null,
            consignor_city: null,
            consignee_name: null,
            consignee_countrycode: null,
            consignee_city: null,
            pickup_address: null,
            delivery_address: null,
            carrier_from_source: null,
            consignment_no: null,
            order_no: null,
            owner_site_from_source: null,
            owner_from_source: null,
            exception_at: null,
            carrier_ids: null,
            carrier_service_ids: null,
            exception_details: null,
            to_postcode: null,
            delivery_postcode: null,
            pickup_postcode: null,
            from_postcode: null,
            has_exception: 0,
            is_in_progress: 0,
            has_loading_meters: 0,
            is_heavy: 0,
            is_time_definite: 0,
            bookmark: null,
            have_internal_comments: 0,
            auto_bookmark: 0,
            has_bookmarks: 0,
            under_investigation: 0,
            consignee_delivery_countrycode: null,
            consignor_pickup_countrycode: null,
            source: null,
            under_snooze: 0,
            under_reminde: 0,
            under_reminder_expired: 0,
            is_archived: null,
            daily_pickup: null,
            direction: null,
        },
        defaults: {
            filters: {
                range: 'last_week',
                eta_date_between: {
                    startDate: '',
                    endDate: ''
                },
                etd_date_between: {
                    startDate: '',
                    endDate: ''
                },
                arrival_date_between: {
                    startDate: '',
                    endDate: ''
                },
                investigated_by: null,
                by_reference: null,
                date_between: null,
                status: null,
                carrier_service_from_source: null,
                carrier_service_id: null,
                transport_mode: null,
                duplicates_by: 0,
                consignor_name: null,
                consignor_countrycode: null,
                consignor_city: null,
                consignee_name: null,
                consignee_countrycode: null,
                consignee_city: null,
                pickup_address: null,
                delivery_address: null,
                carrier_from_source: null,
                owner_site_from_source: null,
                owner_from_source: null,
                exception_at: null,
                carrier_ids: null,
                carrier_service_ids: null,
                exception_details: null,
                to_postcode: null,
                delivery_postcode: null,
                pickup_postcode: null,
                from_postcode: null,
                has_exception: 0,
                is_in_progress: 0,
                has_loading_meters: 0,
                is_heavy: 0,
                is_time_definite: 0,
                bookmark: null,
                have_internal_comments: 0,
                auto_bookmark: 0,
                has_bookmarks: 0,
                under_investigation: 0,
                consignee_delivery_countrycode: null,
                consignor_pickup_countrycode: null,
                source: null,
                under_snooze: 0,
                under_reminde: 0,
                under_reminder_expired: 0,
                is_archived: { name: 'Not archived', value: '0' },
                daily_pickup: null,
                direction: null,
            }
        },
        options: {
            date_between: [],
            owner_site_from_source: [],
            transport_mode: [],
            consignor_name: [],
            consignor_countrycode: [],
            consignor_city: [],
            consignee_name: [],
            consignee_countrycode: [],
            consignee_city: [],
            pickup_address: [],
            delivery_address: [],
            carrier_service_id: [],
            carrier_from_source: [],
            duplicates_by: [],
            owner_from_source: [],
            shipment_status: [],
            exception_at: [],
            exception_details: [],
            carrier_service_from_source: [],
            users: [],
            bookmark: [],
            carrier_ids: [],
            carrier_service_ids: [],
            owner_data_list: [],
            owner_client_list: [],
            is_archived: [],
            daily_pickup: [],
            source: [],
        },

        checkedRows: [],
        saveQuery: {
            title: ''
        },
        saveTemplates: {
            templateData: null,
            title: ''
        },
        loadingConsignments: false,
        loadingFilters: false,
        consignments: [],
        filterParams: {},
        loading: false,
        tableRows: [],
        showImportConsignmentDialog: false,
        filteredCarriers: [],
    }),
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (vm.adminRoute) {
                vm.clearSelectedSite()
                vm.clearFilterOptions()
            }
        })
    },

    beforeRouteLeave(to, from, next) {
        if (this.adminRoute) {
            localStorage.setItem('adminFilters', localStorage.filterParams)
        }
        next();
    },
    computed: {
        vue() {
            return this;
        },
        columns() {
            const columns = [
                {
                    title: 'Consignment no.',
                    key: 'consignment_no',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-48'
                },
                {
                    title: 'Comments External',
                    key: 'count_external_comments',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Comments Internal',
                    key: 'count_internal_comments',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Comments Question',
                    key: 'count_question_comments',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Attachments',
                    key: 'count_documents',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Shippers ref.',
                    key: 'order_no',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Site',
                    key: 'owner_site_from_source',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Date',
                    key: 'ordered_at',
                    toggled: true,
                    toggleable: true,
                    width: 'w-24',
                    sortable: true
                },
                {
                    title: 'From',
                    key: 'consignor_name',
                    toggled: true,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'From address',
                    key: 'consignor_address',
                    toggled: false,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'From city',
                    key: 'consignor_city',
                    toggled: false,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'Exception details',
                    key: 'exception_details',
                    toggled: false,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'From post code',
                    key: 'consignor_postcode',
                    toggled: false,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'From country',
                    key: 'consignor_countrycode',
                    toggled: false,
                    toggleable: true,
                    width: 'w-32',
                    sortable: true
                },
                {
                    title: 'Investigated by',
                    key: 'investigated_by',
                    toggled: false,
                    toggleable: true,
                    width: 'w-32',
                    sortable: false
                },
                {
                    title: 'Recipients ref.',
                    key: 'consignee_reference',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40'
                },
                {
                    title: 'Actual pickup date',
                    key: 'actual_time_pick_up_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40'
                },
                {
                    title: 'Actual delivery date',
                    key: 'actual_time_delivery_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40'
                },
                {
                    title: 'Estimated arrival date',
                    key: 'estimated_arrival_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40'
                },
                {
                    title: 'Payer',
                    key: 'payer',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Combiterms',
                    key: 'combiterms',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Booking template',
                    key: 'booking_number',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-48'
                },
                {
                    title: 'Pickup name',
                    key: 'pickup_name',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Pickup address',
                    key: 'pickup_address',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Pickup city',
                    key: 'pickup_city',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-24',
                },
                {
                    title: 'Pickup post code',
                    key: 'pickup_postcode',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Pickup country',
                    key: 'pickup_countrycode',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Requested pickup',
                    key: 'pick_up_starts_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Estimated Departure Date',
                    key: 'estimated_time_departure_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Actual departure date',
                    key: 'actual_time_departure_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Estimated Delivery Date',
                    key: 'estimated_delivery_starts_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Estimated Arrival Date',
                    key: 'estimated_arrival_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Actual arrival date',
                    key: 'actual_time_arrival_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Mode',
                    key: 'transport_mode',
                    toggled: true,
                    toggleable: true,
                    width: 'w-24',
                    sortable: true
                },
                {
                    title: 'To',
                    key: 'consignee_name',
                    toggled: true,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'To address',
                    key: 'consignee_address',
                    toggled: false,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'To city',
                    key: 'consignee_city',
                    toggled: false,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'To post code',
                    key: 'consignee_postcode',
                    toggled: false,
                    toggleable: true,
                    width: 'w-40',
                    sortable: true
                },
                {
                    title: 'To country',
                    key: 'consignee_countrycode',
                    toggled: false,
                    toggleable: true,
                    width: 'w-32',
                    sortable: true
                },
                {
                    title: 'Delivery name',
                    key: 'delivery_name',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Delivery address',
                    key: 'delivery_address',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-40',
                },
                {
                    title: 'Delivery city',
                    key: 'delivery_city',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Delivery post code',
                    key: 'delivery_postcode',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Delivery country',
                    key: 'delivery_countrycode',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32',
                },
                {
                    title: 'Carrier',
                    key: 'carrier_from_source',
                    toggled: true,
                    toggleable: true,
                    width: 'w-20',
                    sortable: true
                },
                {
                    title: 'Carrier service',
                    key: 'carrier_service_from_source',
                    toggled: false,
                    toggleable: true,
                    width: 'w-48',
                    sortable: true,
                    tdClass: 'td__carrier-service',
                },
                {
                    title: 'Amount',
                    key: 'noofpackages',
                    toggled: false,
                    toggleable: true,
                    alignRight: false,
                    width: 'w-20',
                    sortable: true,
                },
                {
                    title: 'Weight',
                    key: 'dimensions_weight',
                    toggled: true,
                    toggleable: true,
                    alignRight: false,
                    width: 'w-20',
                    sortable: true,
                },
                {
                    title: 'Volume',
                    key: 'dimensions_volume',
                    toggled: false,
                    toggleable: true,
                    alignRight: false,
                    width: 'w-20',
                    sortable: true,
                },
                {
                    title: 'Loading metres',
                    key: 'dimensions_loadingmetres',
                    toggled: false,
                    toggleable: true,
                    alignRight: false,
                    width: 'w-40',
                    sortable: true,
                },
                {
                    title: 'Price',
                    key: 'price_cat1',
                    toggled: true,
                    sortable: true,
                    toggleable: true,
                    pin: false,
                    width: 'w-40'
                },
                {
                    title: 'Is Archived',
                    key: 'is_archived',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Status',
                    key: 'status',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Manual update',
                    key: 'manual_updated_at',
                    toggled: false,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Goods types',
                    key: 'goods_types',
                    toggled: true,
                    sortable: false,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Initial EDD',
                    key: 'etadate',
                    toggled: true,
                    toggleable: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Direction',
                    key: 'direction',
                    toggled: true,
                    sortable: false,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Source',
                    key: 'source',
                    toggled: true,
                    sortable: false,
                    pin: false,
                    width: 'w-32'
                }
            ]

            if (this.adminRoute) {
                this.insertAt(columns, 1, [
                    {
                        title: 'Owner from source',
                        key: 'owner_from_source',
                        toggled: true,
                        toggleable: true,
                        sortable: true,
                        width: 'w-40'
                    }
                ])

                columns.push({
                    title: '',
                    key: 'actions',
                    toggled: true,
                    toggleable: false,
                    sortable: false,
                    overflow: true,
                    width: 'w-24',
                    tdClass: 'actions',
                    pin: false,
                })
            }

            this.togglePurchaseOrdersColumn();

            return columns
        },
        allSites() {
            if (!this.organizations.length) {
                return [];
            }

            return this.organizations.map(item => { return item.sites }).flat();
        },
        adminRoute() {
            return this.$route.name === 'admin.shipments'
        },
        tableRef() {
            return this.adminRoute ? 'admin-consignments' : 'consignments'
        },
        visibleRows() {
            if (this.$refs[this.tableRef]) {
                return this.$refs[this.tableRef].$el.querySelectorAll(
                    'td input[type=checkbox]'
                )
            }

            return []
        },
        user() {
            return this.$store.getters['auth/user']
        },
        adminLevel() {
            return this.user.level === 'super';
        },
        site() {
            return this.$store.getters['auth/site']
        },
        meta() {
            return this.$store.getters['auth/user'].meta
        },
        addressRelatedKeys() {
            const keys = ['name', 'postcode', 'countrycode', 'city', 'address'];

            return ['consignee', 'consignor', 'pickup', 'delivery']
                .reduce((accum, deliveryType) => {
                    keys.forEach(key => {
                        if (!accum[key]) {
                            accum[key] = [];
                        }

                        accum[key].push(`${deliveryType}_${key}`);
                    });

                    return accum;
                }, {});
        },
    },
    watch: {
        shipmentsFilter: {
            async handler(){
                if (!this.pageLoad) {
                    return;
                }

                if (this.isResetData) {
                    return;
                }

                await this.saveFilters();
            },
            deep: true
        },
        checkedRows() {
            if (this.checkedRows.length < this.visibleRows.length) {
                this.allRowsChecked = false
            }
        },
        filters: {
            async handler() {
                this.setFilterParams();

                if (this.shipmentsFilter && this.shipmentsFilter.filters) {
                    const newFilters = this.sendFilterValues();

                    if (JSON.stringify(newFilters) === JSON.stringify(this.shipmentsFilter.filters)) {
                        return;
                    }

                    this.shipmentsFilter.filters = newFilters;
                }
            },
            immediate: false,
            deep: true,
        },
        filterParams: {
            async handler(newValue, oldValue) {
                this.createdComponents = true;

                if (this.watchTrigger === 1) {
                    this.watchTrigger = 0;

                    return;
                }

                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    await this.fetchFilterOptions();
                    await this.clearCheckedRows();
                }
            },
            immediate: false,
            deep: true,
        },
        selectedSite() {
            this.filters.owner_site_from_source = this.selectedSite.map(item => { return item });

            this.handlerOwnerSite = true;
        },
        // 'filters.owner_site'(newValue, oldValue) {
        //     newValue = JSON.stringify(newValue);

        //     if (newValue && newValue !== JSON.stringify(oldValue)) {
        //         this.loading = true;

        //         setTimeout(() => {
        //             this.statistics()
        //         }, 1000);
        //     }
        // },
        statuses() {
            this.filters.status = this.statuses.map(item => { return item.value });
        },
        exception_details() {
            this.filters.exception_details = this.exception_details.map(item => { return item.value });
        },
        exception_at() {
            this.filters.exception_at = this.exception_at.map(item => { return item.value });
        },
        carrier_ids() {
            this.filters.carrier_ids = this.carrier_ids.map(item => { return item.id });
        },
        carrier_service_ids() {
            this.filters.carrier_service_ids = this.carrier_service_ids.map(item => { return item.id });
        },
        carrier_service_id() {
            this.filters.carrier_service_name = this.carrier_service_id.map(item => { return item });
        },
        bookmark() {
            this.filters.bookmark = this.bookmark.map(item => { return item.value });
        },
        investigated_by(value) {
            if (!value) {
                this.filters.investigated_by = null;

                return;
            }

            this.filters.investigated_by = value.id;
        },
        transport_mode() {
            this.filters.transport_mode = this.transport_mode.map(item => {
                if (item === 'UNDEFINED') {
                    return '';
                }

                if (item === 'NULL') {
                    return 'null';
                }

                return item;
            })
        }
    },
    async created() {
        await this.initFilters();

        const isFiltersFromUrl = await this.initFiltersFromUrl();

        if (!isFiltersFromUrl) {
            await this.initFilterValues();
        }

        await this.initUsers();

        this.fetchQueries()
        this.initDictionary();

        let params

        if (!this.adminRoute) {
            if (localStorage.filterParams) {
                params = JSON.parse(localStorage.filterParams)
            }
        }
        
        if (this.adminRoute) {
            this.fetchOrganizations()
        }
    },

    methods: {
        getDeliveryTypeField(key, data) {
            const type = key.split('_')[0];
            const keyType = key.split('_')[1];
            if (keyType === 'name') {
                return this.getName(type, data);
            }

            if (keyType === 'address') {
                return this.getFullAddress(type, data);
            }

            if (keyType === 'postcode') {
                return this.getPostCode(type, data);
            }

            if (keyType === 'countrycode') {
                return this.getCountryCode(type, data);
            }

            if (keyType === 'city') {
                return this.getCityName(type, data);
            }
        },
        getName(type, data) {
            return data[`${type}_full_address`]?.name || data[`${type}_name`] || '-';
        },
        getFullAddress(type, data) {
            return data[`${type}_full_address`]?.complete_address || data[`${type}_complete_address`] || '-';
        },
        getPostCode(type, data) {
            return data[`${type}_full_address`]?.postcode || data[`${type}_postcode`] || '-';
        },
        getCountryCode(type, data) {
            return data[`${type}_full_address`]?.country_code || data[`${type}_countrycode`] || '-';
        },
        getCityName(type, data) {
            return data[`${type}_full_address`]?.city?.name || data[`${type}_city`] || '-';
        },
        getGoodsTypes(data) {
            return data?.map(item => item.name)?.join(', ') || '-';
        },
        prepareExceptionDetails(val) {
            return val.split(',').map(val => val.trim()).filter(val => val).join(', ');
        },
        roundNumber(value, postValue) {
            if (!value) {
                return '-';
            }

            if (postValue) {
                return `${roundNumber(value)} ${postValue}`;
            }

            return roundNumber(value);
        },
        initFiltersFromUrl() {
            const values = this.$route.query.filterParams;

            if (!values) {
                return false;
            }   

            const valuesPrepare = values.split('__')
                .map(item => {
                    const key = item.slice(item.indexOf('[') + 1, item.indexOf(']'));
                    const value = item.slice(item.indexOf('=') + 1);

                    return { key, value };
                })
                .map(item => {
                    const value = item;

                    if (['date_between', 'etd_date_between', 'eta_date_between', 'arrival_date_between'].includes(item.key)) {
                        if (!item.value.includes('20')) {
                            value.value = item.value;
                        } else {
                            value.value = {
                                startDate: moment(new Date(item.value.slice(0, item.value.indexOf(',')))),
                                endDate: moment(new Date(item.value.slice(item.value.indexOf(',') + 1))),
                            }
                        }
                    }

                    if (value.value === '0' || value.value === 1) {
                        value.value = +value.value;
                    }

                    if (item.key === 'is_archived') {
                        if (+value.value === 1) {
                            value.value = { name: 'Archived only', value: '1' };
                        } else if (+value.value === 0) {
                            value.value = { name: 'Not archived', value: '0' };
                        } else {
                            value.value = null;
                        }
                    }

                    if (item.key === 'date_between') {
                        value.key = 'range';
                    }

                    return value;
                })

            const allFilters = [...this.filterHeader, ...this.filterSelects, ...this.filterSwitch];
            
            const filters = allFilters.filter(item => { return item.filter });
            const filtersCustom = allFilters.filter(item => { return !item.filter });

            filters.forEach(item => {
                const isSetup = valuesPrepare.find(key => { return item.model === key.key });
                
                if (isSetup) {
                    this.filters[isSetup.key] = isSetup.value;
                }
            });

            filtersCustom.forEach(item => {
                const isSetup = valuesPrepare.find(key => { return item.model === key.key });

                if (isSetup) {
                    this[isSetup.key] = JSON.parse(isSetup.value);
                }
            });

            return true;
        },
        shareFilterLink() {
            const filterParams = Object.assign({}, this.filterParams);

            if (filterParams[`filter[transport_mode_from_source]`]) {
                filterParams[`filter[transport_mode_from_source]`] = JSON.stringify(this.transport_mode_from_source)
            }

            if (filterParams[`filter[owner_site_from_source]`]) {
                delete filterParams[`filter[owner_site_from_source]`];

                filterParams[`filter[selectedSite]`] = JSON.stringify(this.selectedSite);
            }

            if (filterParams[`filter[carrier_service_ids]`]) {
                filterParams[`filter[carrier_service_ids]`] = JSON.stringify(this.carrier_service_ids);
            }

            if (filterParams[`filter[carrier_ids]`]) {
                filterParams[`filter[carrier_ids]`] = JSON.stringify(this.carrier_ids);
            }

            if (filterParams[`filter[exception_details]`]) {
                filterParams[`filter[exception_details]`] = JSON.stringify(this.exception_details);
            }

            if (filterParams[`filter[investigated_by]`]) {
                filterParams[`filter[investigated_by]`] = JSON.stringify(this.investigated_by);
            }

            if (filterParams[`filter[status]`]) {
                delete filterParams[`filter[status]`];

                filterParams[`filter[statuses]`] = JSON.stringify(this.statuses);
            }

            const filters = serializeParams(filterParams);

            const currentQuery = this.$route.query;

            delete currentQuery.filters;

            const result = `${document.location.href}&filterParams=${filters.replaceAll('&', '__')}`;

            navigator.clipboard.writeText(result)
                .then(() => {
                    this.$snotify.success('Link copied to clipboard');
                })
                .catch(err => {
                    console.log('Something went wrong', err);
            });
        },
        async removeQuery(query) {
            await axios.delete('/api/me/filters/' + query.id);

            const result = await this.fetchQueries();

            if (!result.length) {
                this.showLoadQueryModal = false;
            }
        },
        getIsDisplayPrice(priceData) {
            return priceData?.amount != null && priceData?.currency != null;
        },
        showImportConsignment(){
            this.$nextTick(() => {
                this.showImportConsignmentDialog = true;
            });
        },
        hideImportConsignment(){
            this.showImportConsignmentDialog = false;
        },
        async snoozeSuccess() {
            this.clearCheckedRows();
            await this.refreshTable();
        },
        async initUsers() {
            const query = this.adminLevel ? '?level=super' : '';

            const { data: { data } } = await axios.get(this.$apiUrl.filters + '/users' + query);

            this.usersList = data;
            this.options.users = data;
        },
        updateSortKey(value) {
            if (!this.shipmentsFilter) {
                return;
            }
            
            this.shipmentsFilter.sort_key = value;
        },

        updateSortOrder(value) {
            if (!this.shipmentsFilter) {
                return;
            }

            this.shipmentsFilter.sort_order = value;
        },

        async makeUnseen(uuid) {
            try {
                await axios.post(this.$apiUrl.consignments.notSeen(uuid));

                const clickedRow = this.$refs[this.tableRef].table.rows
                    .find(tableRow => tableRow.uuid === uuid);
                clickedRow.last_seen_by_admin_at = null;
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }
        },

        updatedColumns(value) {
            if (!this.shipmentsFilter) {
                return;
            }

            this.shipmentsFilter.table_settings = value;
        },

        updatedPageSize(value) {
            if (!this.shipmentsFilter) {
                return;
            }

            this.shipmentsFilter.count_rows = value;
        },

        updatedPage(value) {
            this.shipmentsFilter.pagination = value;
        },

        generateFilter(item) {
            if (!item.filter) {
                return this.vue;
            }

            return this.vue['filters'];
        },
        async initDictionary() {
            const { data: { data } } = await axios.get(this.$apiUrl.dictionary)

            this.dictionary = data;

            this.props_exception_details = data.exception_details.map(item => {
                return {
                    label: item.label,
                    id: item.value,
                    active: true,
                    single_only: true,
                };
            });
        },
        updateDashboardItem(value) {
            const selectedSites = this.selectedSite
            const selectedSitesFilter = this.filters.owner_site_from_source

            this.resetData()

            this.selectedSite = selectedSites
            this.filters.owner_site_from_source = selectedSitesFilter

            this.has_exception = 1
            this.filters.has_exception = 1

            this.statuses = [value]
            this.filters.status = this.statuses.map(item => { return item.value })

            this.filters.date_between = 'all'
        },
        // async statistics() {
        //     if (!this.handlerOwnerSite) {
        //         this.loading = false;

        //         return;
        //     }

        //     this.loading = true;

        //     const { data: { data }} = await axios.get(`${this.$apiUrl.consignments.filteredStatistics}?${serializeParams(this.filterParams)}`);

        //     this.statisticsData = {
        //         confirmed: data.find(item => { return item.status === 'CONFIRMED' }),
        //         pickup: data.find(item => { return item.status === 'PICKUP' }),
        //         transit: data.find(item => { return item.status === 'IN_TRANSIT' }),
        //     };

        //     this.loading = false;
        // },
        async sendInformation(target) {
            const emails = [target.consignor_email, target.consignee_email, target.delivery_email, target.pickup_email]
            const workingEmails = emails.filter(item => { return item });

            const exception = await axios.get(`${this.$apiUrl.exceptions.base}?sortKey=created_at&sortOrder=-1&page=1&pageSize=50&exceptionable_id=${target.id}&exceptionable_type=consignment`);

            const exceptionId = exception.data.data[0] ? exception.data.data[0].id : '';

            if (!exceptionId) {
                this.$snotify.error('error getting id exceptions');

                return;
            }

            this.sendInformationData = { id: exceptionId, emails: [...new Set(workingEmails)] };
            this.sendInformationHide = true;
        },
        async resolve(id) {
            try {
                const exception = await axios.get(`${this.$apiUrl.exceptions.base}?sortKey=created_at&sortOrder=-1&page=1&pageSize=50&exceptionable_id=${id}&exceptionable_type=consignment`);

                const exceptionId = exception.data.data[0] ? exception.data.data[0].id : '';

                if (!exceptionId) {
                    this.$snotify.error('error getting id exceptions');

                    return;
                }

                await axios.post(this.$apiUrl.exceptions.resolve, {
                    targets: [{ id: exceptionId }],
                });

                this.$snotify.success(`Resolve successfully`);
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            }
        },
        async changeExceptionAt(actionName, id) {
            const value = actionName === 'all_good' ? null : actionName.toUpperCase();

            try {
                await axios.patch(`${this.$apiUrl.consignments.base}/${id}`, {
                    exception_at: value,
                });

                await this.refreshTable();

                this.$snotify.success(`Successfully`);
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            }
        },
        async changeExceptionDetails(actionName, id) {
            const value = actionName === 'all_good_detail' ? null : actionName.toUpperCase();

            try {
                await axios.patch(`${this.$apiUrl.consignments.base}/${id}`, {
                    exception_details: value,
                });

                await this.refreshTable();

                this.$snotify.success(`Successfully`);
            } catch (error) {
                this.$snotify.error(error.response.data.message);
            }
        },
        async investigate(id) {
            try {
                const exception = await axios.get(`${this.$apiUrl.exceptions.base}?sortKey=created_at&sortOrder=-1&page=1&pageSize=50&exceptionable_id=${id}&exceptionable_type=consignment`);

                const exceptionId = exception.data.data[0] ? exception.data.data[0].id : '';

                if (!exceptionId) {
                    this.$snotify.error('error getting id exceptions');

                    return;
                }

                await axios.post(this.$apiUrl.exceptions.claim, {
                    targets: [{ id: exceptionId }],
                });

                this.$snotify.success(`Investigate successfully`);
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            }
        },
        async getGenerateEmail(id) {
            axios({
                url: `${this.$apiUrl.consignments.base}/${id}/canned-response-form`,
                method: 'GET',
                contentType: 'application/json',
            }).then(response => {
                this.generateEmailData = response.data.data;
                this.generateEmailData.id = id;
                this.generateEmailHide = true;
            }).catch(error => {
                this.$snotify.error(error.response.data.errors.join(', '))
            })
        },

        insertAt(array, index, elements) {
            array.splice(index, 0, ...elements)
        },

        async archive(id) {
            try {
                await axios.post(`${this.$apiUrl.consignments.base}/${id}/archive`);

                await this.refreshTable();

                this.$snotify.success(`Successfully`);
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },

        async dearchive(id) {
            try {
                await axios.post(`${this.$apiUrl.consignments.base}/${id}/dearchive`);

                await this.refreshTable();

                this.$snotify.success(`Successfully`);
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },

        async runAction(actionName, target = false) {
            this.activeShipment = target;

            if (actionName === 'archive') {
                await this.archive(target.uuid);

                return;
            }

            if (actionName === 'dearchive') {
                await this.dearchive(target.uuid);

                return;
            }

            if (actionName === 'to_price_lead') {
                window.open(`/admin/redirect/price-leadtime?uuid=${target.uuid}&site_id=${target.site_id}`,'_blank');
            }

            if (actionName === 'close-snooze') {
                await this.snoozeCloseSingle([target.uuid]);

                return
            }

            if (actionName === 'close-reminder') {
                await this.reminderCloseSingle([target.uuid]);

                return;
            }

            if (actionName === 'snooze') {
                this.snoozeModalData = [target];
                this.snoozeModalHide = true;

                return;
            }

            if (actionName === 'set_pick_up_starts_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'pick_up_starts_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_pick_up_ends_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'pick_up_ends_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_delivery_starts_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'delivery_starts_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_delivery_ends_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'delivery_ends_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_actual_time_delivery_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'actual_time_delivery_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_actual_time_departure_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'actual_time_departure_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_estimated_time_departure_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'estimated_time_departure_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_actual_time_arrival_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'actual_time_arrival_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_estimated_delivery_starts_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'estimated_delivery_starts_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'set_estimated_delivery_ends_at') {
                this.setTimeHide = true;
                this.setTimeValue.value = 'estimated_delivery_ends_at';
                this.setTimeValue.id = target.uuid;

                return;
            }

            if (actionName === 'add_comment') {
                this.commentId = target.id || 697659;
                this.commentHide = true;

                return;
            }

            if (actionName === 'add_event') {
                this.$nextTick(() => {
                    this.$modal.show('add-event-modal')

                    return;
                })
            }
            if (actionName === 'edit_shipment') {
                this.$nextTick(() => {
                    this.$modal.show('edit-shipment-modal')

                    return;
                })
            }

            if (
                actionName === 'pick_up' ||
                actionName === 'transit' ||
                actionName === 'customs' ||
                actionName === 'delivery' ||
                actionName === 'all_good'
            ) {
                this.changeExceptionAt(actionName, target.uuid);

                return;
            }

            const findExceptionDetails = this.props_exception_details.find(item => {
                return item.id === actionName;
            });

            if (findExceptionDetails) {
                this.changeExceptionDetails(actionName, target.uuid);

                return;
            }

            if (actionName === 'send_information') {
                this.sendInformation(target);

                return;
            }

            if (actionName === 'generate_email') {
                this.getGenerateEmail(target.uuid);

                return;
            }

            if (actionName === 'resolve') {
                this.resolve(target.id);

                return;
            }

            if (actionName === 'investigate') {
                this.investigate(target.id);

                return;
            }

            return false
        },
        async toExport() {
            const consignments = this.checkedRows.map(i => i.id).join(',')
            const columns = this.$refs[this.tableRef].table.toggledColumns.map(item => {
                if (item.key === 'carrier_service_from_source') {
                    return {
                        title: item.title,
                        key: 'carrier_service_name',
                    }
                }

                return {
                    title: item.title,
                    key: item.key,
                }
            });

            replacePriceExportColumn(columns);

            const consignmentsFilter = consignments ? `&filter[id]=${consignments}` : '';

            try {
                await axios.post(`${this.$apiUrl.consignments.export}?${serializeParams(this.filterParams)}${consignmentsFilter}`, { columns });

                this.$snotify.success('Your task is in queue. Check your mailbox in 5 minutes');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },
        clearFilterOptions() {
            this.options = defaultFilterOptions()
        },
        clearFilter(filter) {
            this.filters[filter] = null
        },
        savedRows() {
            if (!this.user.meta) {
                return []
            }

            return this.user.meta.consignments
        },
        getTableRows(value) {
            this.tableRows = value;
        },
        closeContactModal() {
            this.contactModal = false
            this.contact.sent = false
            this.contact.subject = ''
            this.contact.message = ''
            this.contact.withError = false
        },
        showContactModal() {
            this.contactModal = true
        },
        clone(obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        setDefaults() {
            this.filters = Object.assign({}, this.defaults.filters);
            this.filters.range = 'last_week';
            this.selectedSite = []
            this.loadedRange = this.filters.range
            this.selectedSite = [];
            this.statuses = [];
            this.exception_details = [];
            this.exception_at = [];
            this.carrier_ids = [];
            this.carrier_service_ids = [];
            this.transport_mode = [];
            this.bookmark = [];
            this.carrier_service_id = []
            this.order_no = '';
            this.to_postcode = '';
            this.from_postcode = '';
            this.delivery_postcode = '';
            this.pickup_postcode = '';
            this.investigated_by = null;
        },
        async resetData(showNotify = true) {
            this.isResetData = true;

            await this.setDefaults()

            const query = {
                filters: this.clone(this.defaults.filters)
            }

            this.datesTrigger++;
            this.$store.commit('shipments/SET_QUERY', { query });
            if (showNotify) {
                this.$snotify.success('Form cleared');
            }

            await this.saveFilters();

            this.isResetData = false;
        },
        parseRangeDate(range) {
            if (typeof this.filters[range] === 'string') {
                return this.filters[range];
            }

            if (this.filters[range] && !this.filters[range].startDate) {
                return '';
            }

            return this.filters[range].startDate.format('YYYY-MM-DD') + ',' + this.filters[range].endDate.format('YYYY-MM-DD');
        },
        async setFilterParams() {
            this.loading = true;
            let params = {
                'filter[date_between]': this.parseRangeDate('range'),
            }

            if (this.parseRangeDate('etd_date_between')) {
                params['filter[etd_date_between]'] = this.parseRangeDate('etd_date_between');
            }

            if (this.parseRangeDate('eta_date_between')) {
                params['filter[eta_date_between]'] = this.parseRangeDate('eta_date_between');
            }

            if (this.parseRangeDate('arrival_date_between')) {
                params['filter[arrival_date_between]'] = this.parseRangeDate('arrival_date_between');
            }

            let keys = Object.keys(this.filters);

            
            
            keys.forEach(filter => {
                if (this.filters[filter] && !['range', 'eta_date_between', 'etd_date_between', 'arrival_date_between'].includes(filter)) {                    
                    if (Array.isArray(this.filters[filter]) && !this.filters[filter].length) {
                        return;
                    }
                    
                    if (filter === 'direction') {
                        params['filter[' + filter + ']'] = this.filters[filter]?.map(val => val.id);
                        
                        return;
                    }

                    if (filter === 'source') {
                        params['filter[' + filter + ']'] = this.filters[filter]?.map(val => val.value);
                        
                        return;
                    }
                    
                    params['filter[' + filter + ']'] = this.filters[filter].value ? this.filters[filter].value : this.filters[filter]
                }
            })

            this.filterParams = params
            localStorage.filterParams = JSON.stringify(params)

            this.loading = false;
        },
        rowChecked(row) {
            if (row === 'checkAllRows') {
                this.checkedRows = this.$refs[this.tableRef].table.visibleRows.slice()

                return
            }

            if (row === 'uncheckAllRows') {
                this.checkedRows = []
                return
            }

            if (this.checkedRows.indexOf(row) !== -1) {
                this.checkedRows.splice(this.checkedRows.indexOf(row), 1)
            } else {
                this.checkedRows.push(row)
            }
        },
        rowClicked(row) {
            if (this.adminRoute) {
                const clickedRow = this.$refs[this.tableRef].table.rows.find(tableRow => tableRow.id === row.data.id);
                clickedRow.last_seen_by_admin_at = moment();
                
                window.open(`/admin/shipments/${row.data.uuid}`, '_blank');

                return;
            }
            this.$router.push({
                name: 'transport.shipments.detail',
                params: {
                    uuid: row.data.uuid
                }
            })
        },
        formatDate(date) {
            if (!date) {
                return '-';
            }

            return moment(date).format('YYYY-MM-DD')
        },
        getTimePlaceholder(date) {
            if (!date) {
                return '-';
            }

            return date + '+02:00';
        },
        timeAgo(date) {
            if (!date) {
                return '-';
            }

            const momentDate = moment(date + '+0200');


            let diff = moment().diff(momentDate, 'years');

            if (diff >= 1) {
                return `${diff} year(s) ago`;
            }

            diff = moment().diff(momentDate, 'months');

            if (diff >= 1) {
                return `${diff} month(s) ago`;
            }

            diff = moment().diff(momentDate, 'days');

            if (diff >= 1) {
                return `${diff} day(s) ago`;
            }

            diff = moment().diff(momentDate, 'hours');

            if (diff >= 1) {
                return `${diff} hour(s) ago`;
            }

            return 'less than 1 hour ago';
        },
        onClose() {
            this.toggleTableTools = false
        },
        parseCarrierService(value) {
            // const filteredCarriers = this.filteredCarriers;

            // const allServices = filteredCarriers.map(item => { return item.services }).flat();

            // const settings = allServices.find(item => { return item.name === value });

            // if (settings) {
            //     return `${value} ${this.parseTransportService(settings)}`;
            // }

            return value;
        },
        parseTransportService(service) {
            const value = [
                service.first_transport_service_setting,
                service.second_transport_service_setting,
                service.third_transport_service_setting,
                service.fourth_transport_service_setting ,
            ];

            const result = value.filter(item => { return item });

            return result.join(', ');
        },
        async refreshTable() {
            this.$refs[this.tableRef].table.fetchRows()
        },
        async fetchFilterOptions() {
            const { data } = await axios.get(this.$apiUrl.filterOptions + serializeParams(this.filterParams));

            let response = data.data
            let keys = Object.keys(this.options)

            this.owner_list = data.data.owner_from_source || [];

            this.clearFilterOptions();

            this.options.owner_site_from_source = [];

            keys.forEach(option => {
                if (['carrier_from_source', 'carrier_service_from_source', 'owner_data'].includes(option)) {
                    return;
                }

                if (option === 'transport_mode') {
                    this.options[option] = response['transportservice_transportmode'].filter(item => item);

                    return;
                }

                if (response[option]) {
                    this.options[option] = response[option].filter(item => item);
                }
            });

            const filteredCarriers = data.data.carrier_data.filtered_carriers;

            if (!this.filteredCarriers.length) {
                this.filteredCarriers = filteredCarriers;
            }

            this.options['carrier_ids'] = data.data.carrier_data.all_carriers;
            this.options['owner_data_list'] = data.data.owner_data.filtered_owners;
            this.options['owner_client_list'] = data.data.owner_data.all_owners;
            this.options['is_archived'] = [
                { name: 'Not archived', value: '0' },
                { name: 'Archived only', value: '1' },
            ];
            this.options['source'] = filterOptions.sourceAdmin;
            this.options['daily_pickup'] = [
                { name: 'Not daily pickup', value: '0' },
                { name: 'Daily pickup only', value: '1' },
            ];

            if (!filteredCarriers.length) {
                this.options['carrier_service_ids'] = [];
                // this.options['transport_mode'] = [];
            }

            if (filteredCarriers.length) {
                this.options['carrier_service_ids'] = filteredCarriers.map(item => {
                    return {
                        name: item.carrier.name,
                        items: item.services,
                    }
                });
            }

            this.options.users = this.usersList;

            console.log('this.options', this.options);
            
        },
        async fetchAllFilterOptions() {
            this.loadingFilters = true

            const filterParamsValues = serializeParams(this.filterParams);

            if (!filterParamsValues) {
                return;
            }

            const { data } = await axios.get(this.$apiUrl.filterOptions + filterParamsValues);

            this.owner_list = data.data.owner || [];

            this.loadingFilters = false

            let response = data.data
            let keys = Object.keys(this.options)

            keys.forEach(option => {
                if (response[option]) {
                    this.options[option] = response[option]
                }
            })

            this.options.users = this.usersList;
        },
        async loadQuery(query) {
            await this.resetData();

            let title = query.title
            let filters = Object.assign({}, query.data.filters);

            if (filters.eta_date_between.endDate) {
                filters.eta_date_between.endDate = moment(filters.eta_date_between.endDate);
                filters.eta_date_between.startDate = moment(filters.eta_date_between.startDate);
            }

            if (filters.etd_date_between.endDate) {
                filters.etd_date_between.endDate = moment(filters.etd_date_between.endDate);
                filters.etd_date_between.startDate = moment(filters.etd_date_between.startDate);
            }

            if (filters.arrival_date_between.endDate) {
                filters.arrival_date_between.endDate = moment(filters.arrival_date_between.endDate);
                filters.arrival_date_between.startDate = moment(filters.arrival_date_between.startDate);
            }

            if (filters.range.endDate) {
                filters.range.endDate = moment(filters.range.endDate);
                filters.range.startDate = moment(filters.range.startDate);
            }

            this.selectedSite = filters.owner_site_from_source;
            this.statuses = filters.status;
            this.exception_details = filters.exception_details;
            this.exception_at = filters.exception_at;
            this.carrier_ids = filters.carrier_ids;
            this.carrier_service_ids = filters.carrier_service_ids;
            this.carrier_service_id = filters.carrier_service_name;
            this.transport_mode = filters.transport_mode;
            this.order_no = filters.order_no;
            this.to_postcode = filters.to_postcode;
            this.delivery_postcode = filters.delivery_postcode;
            this.pickup_postcode = filters.pickup_postcode;
            this.by_reference = filters.by_reference;
            this.from_postcode = filters.from_postcode;

            if (filters.investigated_by) {
                this.investigated_by = filters.investigated_by;
            }

            if (filters.status && filters.status.length) {
                filters.status = filters.status.map(item => { return item.value });
            }

            if (filters.exception_details && filters.exception_details.length) {
                filters.exception_details = filters.exception_details.map(item => { return item.value });
            }

            if (filters.exception_at && filters.exception_at.length) {
                filters.exception_at = filters.exception_at.map(item => { return item.value });
            }

            if (filters.carrier_ids && filters.carrier_ids.length) {
                filters.carrier_ids = filters.carrier_ids.map(item => { return item.id });
            }

            if (filters.carrier_service_ids && filters.carrier_service_ids.length) {
                filters.carrier_service_ids = filters.carrier_service_ids.map(item => { return item.id });
            }

            if (filters.transport_mode && filters.transport_mode.length) {
                filters.transport_mode = filters.transport_mode.map(item => {
                    if (item === 'UNDEFINED') {
                        return '';
                    }

                    if (item === 'NULL') {
                        return 'null';
                    }

                    return item;
                })
            }

            if (filters.carrier_service_name && filters.carrier_service_name.length) {
                filters.carrier_service_name = filters.carrier_service_name.map(item => { return item });
            }

            if (filters.owner_site_from_source && filters.owner_site_from_source.length) {
                filters.owner_site_from_source = filters.owner_site_from_source.map(item => { return item.key });
            }

            if (filters.bookmark && filters.bookmark.length) {
                this.bookmark = filters.bookmark;
            }

            this.filters = filters;

            this.loadedRange = this.filters.range;

            this.showLoadQueryModal = false;

            this.datesTrigger++;

            this.$snotify.success(`Query "${title}" loaded!`)
        },
        loadTemplate(index, template) {
            let title = template.title
            this.goods[index] = template.template_data

            this.showLoadTemplatesModal = false
            this.$snotify.success(`Template "${title}" loaded!`)
        },
        clearCheckedRows() {
            this.checkedRows = []

            if (!this.$refs[this.tableRef]) {
                return;
            }

            this.$refs[this.tableRef].table.checkedRows = []
        },
        async postSaveTemplatesTemplate() {
            this.savingTemplate = true

            let title = this.saveTemplates.title

            const { data } = await axios.post(this.$apiUrl.shipments.templates, {
                templateData: this.saveTemplates.templateData,
                title: title
            })

            let templates = data.data
            this.$store.commit('shipments/FETCH_SAVED_TEMPLATES_SUCCESS', { templates })

            this.savingTemplate = false
            this.showSaveTemplatesModal = false

            this.$snotify.success(`Template "${title}" saved!`)
        },
        async postSaveQuery() {
            this.savingQuery = true

            let title = this.saveQuery.title

            const filters = Object.assign({}, this.filters);

            const queryData = {
                filters
            }

            queryData.filters.owner_site_from_source = this.selectedSite;
            queryData.filters.status = this.statuses;
            queryData.filters.exception_details = this.exception_details;
            queryData.filters.exception_at = this.exception_at;
            queryData.filters.carrier_ids = this.carrier_ids;
            queryData.filters.carrier_service_ids = this.carrier_service_ids;
            queryData.filters.transport_mode = this.transport_mode;
            queryData.filters.bookmark = this.bookmark;
            queryData.filters.carrier_service_name = this.carrier_service_id;
            queryData.filters.investigated_by = this.investigated_by;

            const query = prepareFiltersForSave(queryData.filters);

            const { data } = await axios.post(this.$apiUrl.filter.base, {
                data: queryData,
                query,
                title: title,
                category: filterCategory,
            })

            let queries = data.data
            this.$store.commit('shipments/FETCH_SAVED_QUERIES_SUCCESS', { queries })

            this.savingQuery = false
            this.showSaveQueryModal = false

            this.$snotify.success(`Query "${title}" saved!`)

            this.saveQuery.title = '';

            await this.fetchQueries();
        },
        async fetchQueries() {
            const { data: { data } } = await axios.get(`${this.$apiUrl.filter.base}?filter[category]=${filterCategory}`);
            this.loadedQueries = data;

            return data;
        },
        async sendToVchain() {
            this.sending = true

            try {
                await axios.post(this.$apiUrl.consignments.notify, {
                    message: this.contact.message,
                    subject: this.contact.subject,
                    recipient: this.contact.recipient,
                    type: this.contact.type,
                    consignments: this.checkedRows
                })

                this.withError = false
            } catch (error) {
                this.withError = error
            }

            this.contact.sending = false
            this.contact.sent = true
        },
        filterBookmarked(value) {
            return this.checkedRows
                .filter(item => {
                    return item.bookmark === value;
                })
                .map(item => {
                    return item.uuid;
                });
        },
        snoozeAll() {
            this.snoozeModalData = this.checkedRows;
            this.snoozeModalHide = true;
        },
        async updateBookmarked() {
            const created = this.checkedRows.filter(i => !i.bookmarks.length).map(i => i.id );
            const deleted = this.checkedRows.filter(i => i.bookmarks.length).map(i => i.id);

            try {
                if (created.length) {
                    await axios.post(this.$apiUrl.consignments.bookmarks, { ids: created });
                }

                if (deleted.length) {
                    await axios.delete(this.$apiUrl.consignments.bookmarks, { data: { ids: deleted } });
                }

                this.$snotify.success('Successfully!')

                this.refreshTable();
                this.clearCheckedRows()
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            }
        },
        async snoozeAllClose() {
            try {
                const uuids = this.checkedRows.map(i => i.uuid);

                await axios.post(this.$apiUrl.consignments.snoozeClose, { uuids });

                this.$snotify.success('Successfully')

                this.clearCheckedRows();
                this.refreshTable();
            } catch(error) {
                this.$snotify.error('Fail!');
            }
        },
        async reminderAllClose() {
            try {
                const uuids = this.checkedRows.map(i => i.uuid);

                await axios.post(this.$apiUrl.consignments.remindersClose, { uuids });

                this.$snotify.success('Successfully')

                this.clearCheckedRows();
                this.refreshTable();
            } catch(error) {
                this.$snotify.error('Fail!');
            }
        },
        async snoozeCloseSingle(uuids) {
            try {
                await axios.post(this.$apiUrl.consignments.snoozeClose, { uuids });

                this.$snotify.success('Successfully')

                this.clearCheckedRows();
                this.refreshTable();
            } catch(error) {
                this.$snotify.error('Fail!');
            }
        },
        async reminderCloseSingle(uuids) {
            try {
                await axios.post(this.$apiUrl.consignments.remindersClose, { uuids });

                this.$snotify.success('Successfully')

                this.clearCheckedRows();
                this.refreshTable();
            } catch (error) {
                this.$snotify.error('Fail!');
            }
        },
        async fetchOrganizations() {
            let url = null

            if (this.user.level === 'super') {
                url = '?pageSize=1000&include=sites'
            }

            if (url != null) {
                const { data } = await axios.get(this.$apiUrl.organizations.base + url)
                this.organizations = data.data
            }
        },
        setSiteFilter() {
            if (!this.selectedSite.length) {
                this.filters.owner_site_from_source = 0;

                return;
            }

            this.filters.owner_site_from_source = this.selectedSite.map(item => { return item });
        },
        nukeSelectedSite() {
            this.selectedSite = [];
            this.filters.owner_site_from_source = null;
        },
        onStart(e) {
            this.asFilterDrag = true;

            const result = (e.item.className.indexOf('checkbox') + 1 || e.item.className.indexOf('switch') + 1);

            if (result) {
                this.hotGroup = 'b';

                return;
            }

            this.hotGroup = 'a';
        },
        clearSelectedSite() {
            this.selectedSite = [];

            this.filters.owner_site_from_source = null;
            if (this.adminRoute) {
                this.clearFilterOptions()
                this.fetchAllFilterOptions()
            }
        },

        togglePurchaseOrdersColumn() {
            if (this.$refs && this.$refs[this.tableRef] && this.$refs[this.tableRef].table) {
                for (const column of this.$refs[this.tableRef].table.columns) {
                    if (column.key === 'actions') {
                        column.tdClass = 'actions';
                        column.width = 'w-12';
                        column.pin = false;
                    }
                }
            }
        },
        onFilterControl() {
            this.copyFiltersDrag = {
                filterHeader: [...this.filterHeader],
                filterSelects: [...this.filterSelects],
                filterSwitch: [...this.filterSwitch]
            }

            this.asFilterControl = true;
            this.showAdvanced = true;
        },

        cancelFilterControl() {
            this.filterHeader = this.copyFiltersDrag.filterHeader;
            this.filterSelects = this.copyFiltersDrag.filterSelects
            this.filterSwitch = this.copyFiltersDrag.filterSwitch;

            this.asFilterControl = false;
            this.showAdvanced = false;
        },

        async saveFilterControl() {
            this.shipmentsFilter.filters = this.sendFilterValues();

            this.asFilterControl = false;
            this.showAdvanced = false;

            this.$snotify.success(`Filters saved successfully`);
        },

        async initBaseFilters(filters) {
            const result = {
                table_settings: this.columns,
                pagination: 1,
                count_rows: 50,
                sort_key: 'ordered_at',
                sort_order: '-1',
                filters: this.sendFilterValues(),
            }

            await axios.post(this.$apiUrl.users.filters, {
                ...filters,
                shipments: JSON.stringify(result),
                shipmentsClient: filters?.shipmentsClient || null,
            });

            this.shipmentsFilter = result;
        },

        async initFilters() {
            if (!localStorage.getItem('admin-shipments-filters_v') || isNaN(+localStorage.getItem('admin-shipments-filters_v'))) {
                localStorage.setItem('admin-shipments-filters_v', this.filtersVersion);
            }

            const savedFiltersVersion = +localStorage.getItem('admin-shipments-filters_v');

            try {
                const { data: { data: { filters } } } = await axios.get(this.$apiUrl.whoami);

                if ((savedFiltersVersion < this.filtersVerions) || !filters.shipments) {
                    await this.initBaseFilters(filters);

                    return;
                }

                const result = JSON.parse(filters.shipments);

                const resultAllFilters = [
                    ...(result?.filters?.filterHeader ?? []),
                    ...(result?.filters?.filterSelects ?? []),
                    ...(result?.filters?.filterSwitch ?? []),
                ];

                const allFilters = [
                    ...this.filterHeader,
                    ...this.filterSelects,
                    ...this.filterSwitch,
                ]

                if (resultAllFilters.length != allFilters.length) {
                    await this.initBaseFilters(filters);

                    return;
                }

                this.shipmentsFilter = result;
            } catch(error) {
                console.error(error);
            } finally {
                this.isLoadingTable = false;
            }
        },

        async saveFilters() {
            try {
                const { data: { data: { filters } } } = await axios.get(this.$apiUrl.whoami);

                await axios.post(this.$apiUrl.users.filters, {
                    ...filters,
                    shipments: JSON.stringify(this.shipmentsFilter),
                    shipmentsClient: filters?.shipmentsClient || null,
                });

            } catch(error) {
                console.error(error);
            }
        },

        sendFilterValues() {
            const filters = {
                filterHeader: this.filterHeader,
                filterSelects: this.filterSelects,
                filterSwitch: this.filterSwitch,
            };

            for (let key in filters) {
                filters[key].forEach(item => {
                    if (item.filter) {
                        item.value = this.filters[item.model];

                        return;
                    }

                    item.value = this[item.model];
                });
            }

            return filters;
        },

        initFilterValues() {
            const filters = this.shipmentsFilter?.filters;

            if (!filters) {
                return;
            }

            if (this.$route.query.resetData) {
                this.filterHeader = filters.filterHeader;
                this.filterSelects = filters.filterSelects;
                this.filterSwitch = filters.filterSwitch;

                this.resetData(true)

                return;
            }

            for (let key in filters) {
                filters[key].forEach(item => {
                    if (!item.value) {
                        return;
                    }

                    if (item.typeForm === 'date') {

                        if (item.value === 'all' || (item.value && !item.value.startDate)) {
                            this.filters[item.model] = item.value;
                        } else {
                            item.value.startDate = moment(new Date(item.value.startDate));
                            item.value.endDate = moment(new Date(item.value.endDate));

                            this.filters[item.model] = item.value;
                        }

                        return;
                    }

                    if (item.filter) {
                        this.filters[item.model] = item.value;

                        return;
                    }

                    this[item.model] = item.value;
                });
            }

            this.filterHeader = filters.filterHeader;
            this.filterSelects = filters.filterSelects;
            this.filterSwitch = filters.filterSwitch;
        }
    },

    mounted() {
        this.initDragg = true;
    }
}
</script>

<style lang="scss">
.shipments-table {
    .transportmode-icon {
        position: relative;

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .app-table__th {
        margin-right: 8px;
    }
    .app-table__td {
        padding-right: 8px;
        margin-right: 8px;
    }
    .app-table__tbody .app-table__tr:hover {
        .app-table__td.actions, .app-table__td.sticky {
            background-color: rgba(246, 246, 246, var(--bg-opacity)) !important;

            .as-trigger {
                background-color: rgba(246, 246, 246, var(--bg-opacity)) !important;
            }
        }
    }

    .app-table__tbody .app-table__tr_loading:hover {
        .app-table__td.actions, .app-table__td.sticky {
            background-color: white !important;
        }
    }

    .app-table__td.actions {
        position: sticky;
        right: 0;
        padding-right: 0 !important;
    }

    .action-block {
        display: flex;
        justify-content: center;

        svg {
            margin-left: 0 !important;
        }
    }

    // .the-last.actions .top-0 {
    //     top: auto;
    //     bottom: 0;
    // }

    .app-table__td.sticky {
        background-color: white;
    }
}

.load-query-modal {
    .overflow-visible,
    .modal__body {
        overflow: visible;
    }
}

.google-sheets-trigger:hover {
    .google-sheets__wrapper {
        display: flex;
    }
}

.google-sheets {
    &-trigger {
        &:before {
            content: '';
            position: absolute;
            width: 34px;
            height: 6px;
            bottom: -6px;
            left: 0;
        }
    }

    &__wrapper {
        overflow-y: auto;
        position: absolute;
        top: calc(100% + 6px);
        left: 50%;
        transform: translateX(-50%);
        text-align: left;
        display: none;
        flex-direction: column;
        border: 1px solid #E6E6E6;
        background-color: white;
        box-shadow: 0 4px 35px 0px rgb(0 0 0 / 4%), 0 4px 6px 0px rgb(0 0 0 / 3%);
        max-width: 200px;
        max-height: calc(2.5rem * 5);
        min-width: 120px;
        z-index: 100;
    }

    &__item {
        text-align: left;
        padding: 12px;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 0.75rem;

        &:hover {
            background-color: #20984B;
            color: white;
        }
    }
}

.shipments-switch-block {
    display: flex;
    flex-wrap: wrap;

    .switch,
    .checkbox {
        margin-right: 0 !important;
        min-width: 90px;
        max-width: calc(150px);
        width: 100%;
        margin-bottom: 1rem;
        height: 48px;
        margin-right: 1rem;

        .cursor-pointer {
            margin-top: 6px !important;
        }

        .mr-5 {
            margin: 0 !important;
        }

        &.mt-2 {
            margin-top: 0 !important;
        }
    }
}

.shipments-selects-block {
    .select,
    .multi-select,
    .multi-select-colors,
    .date,
    .input,
    .autocomplete
    {
        margin-right: 1rem;
        width: calc((100% / 5) - 1rem);
        min-width: calc((100% / 5) - 1rem);
        max-width: calc((100% / 5) - 1rem);
    }
}

.shipments-hot-block {
    flex-wrap: wrap;
    margin-right: 1rem;
    .select,
    .multi-select,
    .multi-select-colors,
    .input,
    .date,
    .autocomplete
    {
        margin-right: 1rem;
        width: calc((100% / 5.6) - 1rem);
        max-width: calc((100% / 5.6) - 1rem);
    }

    .switch,
    .checkbox {
        margin-right: 1rem;
        min-height: 48px;

        .mr-5 {
            margin-right: 0 !important;
        }

        &.mt-2, &.mt-5, &.mt-4 {
            margin-top: 0 !important;
            padding-top: 1rem;
        }
    }

    .item {
        margin-bottom: 1rem;
    }
}

.shipments-hot-panel {
    align-items: flex-start;

    &__bar {
        margin-top: 22px;
        display: flex;
        justify-content: flex-end;
    }
}

.shipments-filters-drag {
    .text-draggable {
        z-index: 1;
    }

    .sortable-chosen {
        background: transparent;
    }

    .shipments-drag-zone {
        position: relative;

        // .item {
        //     z-index: 10;
        // }

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,1);
            opacity: 0;
            border-radius: 4px;
            transition: opacity .3s;
        }

        &--active {
            &:before {
                opacity: 0.03;
            }
        }
    }

    .shipments-hot-block.shipments-drag-zone,
    .shipments-selects-block.shipments-drag-zone,
    .shipments-switch-block-wrapper.shipments-drag-zone {
        &:before {
            top: -1rem;
            left: -1.5rem;
            width: calc(100% + 1.5rem);
            height: calc(100% + 1rem);
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
        }
    }

    .shipments-selects-block.shipments-drag-zone {
        &:before {
            border-top-left-radius: 0;
            border-top-right-radius: 4px;
        }
    }

    .shipments-switch-block-wrapper.shipments-drag-zone {
        &:before {
            width: calc(100% + 2.5rem);
            left: -1rem;
        }
    }

    .shipments-hot-panel

    .checkbox, .switch {
        .toggle__line {
            transition: background .3s !important;
        }
    }

    &--active {
        .text-draggable {
            cursor: move;
            // transition: color .2s;

            // &:hover {
            //     color: #20984B;
            // }
        }

        .multi-select,
        .select,
        .multi-select-colors,
        .autocomplete {
            .multiselect__tags {
                border-style: dashed;
            }
        }

        .date {
            input {
                border-style: dashed;
            }
        }

        .input input {
            border-style: dashed;
        }

        .checkbox, .switch {
            .toggle__line {
                border: 1px dashed rgba(0,0,0,0.1);
            }
        }
    }
}

.shipments-all-filters {
    &:before {
        position: absolute;
        left: 0;
        width: calc(100%);
        content: '';
        height: 1px;
        background: #E6E6E6;
    }
}

.page-admin-shipments {
    .scroller {
        &::-webkit-scrollbar {
            margin-top: 10px;
        }
        max-height: calc(100vh - 325px);
    }

    .td__carrier-service {
        position: relative;

        strong {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            line-height: 42.96px;
            padding: 0 4px;
        }
    }
}
</style>
