<template>
    <span 
        v-if="items.length" 
        class="expansion-wrapper" 
        @click.stop="stop"
    >
        <v-popover>
            <span class="expansion-wrapper__item sites">
                <span>
                    {{ items.length }} {{ textCount }}
                </span>
            </span>

            <span slot="popover" class="expansion-wrapper-card absolute z-50 flex flex-col bg-white border min-w-48">
                <span class="expansion-wrapper-card__container">
                    <span 
                        v-for="(item, index) in items"
                        :key="index + 'expansion'"
                        class="expansion-wrapper-card__item z-50 cursor-pointer hover:bg-gray-100 block h-32 h-auto py-1 overflow-visible"
                        :title="item[itemParamValue]">

                        <router-link :to="{ name: routeName, params: { [routeParamName]: routeLink ? item[routeLink] : item.id } }">
                            {{ item[itemParamValue] }}
                        </router-link>
                    </span>
                </span>
            </span>
        </v-popover>
    </span>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
    name: 'PurchaseOrdersColumn',
    props: {
        items: {
            type: Array,
        },

        textCount: {
            type: String
        },

        itemParamValue: {
            type: String
        },

        routeName: {
            type: String
        },

        routeParamName: {
            type: String
        },

        routeLink: {
            type: String
        }
    },
    methods: {
        stop() {
            return false;
        },
    },
    components: {
        VPopover,
    }
}

</script>


<style lang="scss" scoped>
.expansion-wrapper {
    display: inline-block;
    width: 100%;
}

.expansion-wrapper__item {
    border-bottom: 1px dotted;
    position: relative;
}

.expansion-wrapper-card {
    display: flex;
    flex-direction: column;
    right: -84px;
    transition: 0.2s all ease-in-out;
    margin-top: 21px;

    &:after, &:before {
        bottom: 100%;
        left: 60%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;

        border-color: rgba(213, 213, 213, 0);
        border-bottom-color: #fff;
        border-width: 15px;
        margin-left: -15px;
    }

    &:before {
        border-color: rgba(245, 2, 2, 0);
        border-bottom-color: #E6E6E6;
        border-width: 16px;
        margin-left: -16px;
    }

    &.hidden {
        display: none !important;
    }
}

.expansion-wrapper-card__container {
    display: inline-block;
    max-height: 150px;
    height: auto;
    overflow-y: auto;
    width: 100%;
}

.expansion-wrapper-card__item {
    display: flex;

    a {
        display: inline-block;
        width: 100%;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        text-align: center;
    }
}
</style>